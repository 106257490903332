<template>
  <main id="content" role="main">
    <div class="container">
      <div class="d-md-flex justify-content-between align-items-center py-3">
        <div class="mb-1 mb-md-0">
          <h1 class="h4 mb-0">
            Token Transactions&nbsp;<span class="small text-secondary"></span>
            <p class="mb-0 text-break small">
              <span
                id="ContentPlaceHolder1_divPageTitle"
                class="small text-secondary"
              >
                For Block
                <a href="#" @click.prevent="showBlock(currentBlock)"
                  ><span class="text-primary text-break">{{
                    currentBlock
                  }}</span></a
                >
              </span>
            </p>
          </h1>
        </div>
      </div>
    </div>

    <div class="container space-bottom-2">
      <span id="ContentPlaceHolder1_lblAdResult"></span>
      <div id="ContentPlaceHolder1_mainrow" class="card">
        <div class="card-body">
          <div
            class="
              d-flex
              flex-wrap flex-xl-nowrap
              text-nowrap
              align-items-center
              ml-auto
            "
          ></div>

          <div class="table-responsive mb-2 mb-md-0" id="paywall_mask">
            <table class="table table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col" width="1">Txn Hash</th>
                  <th scope="col" width="1" class="d-none d-sm-table-cell">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">Block</div>
                    </div>
                  </th>
                  <th scope="col" width="1" class="d-none d-sm-table-cell">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">Time</div>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">From</div>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="d-flex align-items-center">
                      <div class="mr-2">To</div>
                    </div>
                  </th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody v-loading="loading">
                <tr v-for="item in txns" :key="item._id">
                  <td>
                    <span class="hash-tag text-truncate"
                      ><a
                        href="#"
                        @click.prevent="showTransaction(item.hash)"
                        class="myFnExpandBox_searchVal"
                        >{{ item.hash }}</a
                      ></span
                    >
                  </td>
                  <td class="d-none d-sm-table-cell">
                    <a href="#" @click.prevent="showBlock(currentBlock)">{{
                      currentBlock
                    }}</a>
                  </td>
                  <td class="showDate showAge" style="">
                    <span
                      rel="tooltip"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title=""
                      >{{
                        formatDate(item.timestamp, "yyyy-MM-dd hh:mm:ss")
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="hash-tag text-truncate"
                      data-toggle="tooltip"
                      title=""
                      ><a
                        href="#"
                        @click.prevent="showAddressTransaction(item.from)"
                        >{{ item.from }}</a
                      ></span
                    >
                  </td>
                  <td>
                    <span
                      style="white-space: nowrap"
                      class="hash-tag text-truncate"
                      data-toggle="tooltip"
                      title=""
                      ><a
                        href="#"
                        @click.prevent="showAddressTransaction(item.to)"
                        >{{ item.to }}</a
                      ></span
                    >
                  </td>
                  <td>{{ item.value }} RCH</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>

<script>
import { getName } from "../../public/assets/js/lang.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import route from "../router/index";
import { getUrlKey } from "../../src/assets/js/utils.js";

export default {
  name: "blockTokenTxs",
  data() {
    return {
      txns: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      currentBlock: 0,
      loading: false,
    };
  },
  mounted() {
    this.currentBlock = parseInt(getUrlKey("b"));
    this.loading = true;
    PostNet.PostWith(
      PostNetUrl.getTokenTransactionListByBlock,
      (info) => {
        this.txns = info.data;
        this.loading = false;
      },
      (ERR) => {
        this.loading = false;
      },
      { block: this.currentBlock }
    );
  },
  methods: {
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },

    // 页面的方法
    showBlock: function (blockNum) {
      //   location.href = "block?b=" + blockNum;
      route.push({
        path: "/block",
        query: {
          b: blockNum,
        },
      });
    },
    showTransaction: function (hash) {
      //   location.href = "tx/tokentx.html?h=" + hash;
      route.push({
        path: "/txTokenDetail",
        query: {
          h: hash,
        },
      });
    },
    showAddressTransaction: function (addr) {
      //   location.href = "/tokenaddress?a=" + addr;
      route.push({
        path: "/address",
        query: {
          a: addr,
        },
      });
    },
  },
};
</script>
